@import '../../config';

.modalBackground {
    width: 100%;
    height: 100%;
    background-color: $background-modal-compatible;
    background-color: $background-modal;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContainer {
    margin: 10%;
    width: 20rem;
}

.error-message {
    color: red;
}

.prompt {
    font-size: 25px;
}

.confirm-buttons {
    display: flex;
    justify-content: space-between;
}

.confirm-buttons button {
    background-color: $text-secondary;
    padding: 4%;
    width: 6rem;
    margin: 10%; 
    border-style: none;
    border-radius: 1rem;
    color: black;
}

.delete-button {
    font-weight: bold;
}

.name-fields {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.name-fields input {
    width: 7rem;
    border-bottom: 1px solid white;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0px;
    background-color: $background-modal;
    color: white;
    font-size: 15px;
}

.employee-selectors-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.employee-selectors {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    justify-content: space-between;
}

.employee-selectors select, .employee-selectors span {
    margin: 0.5rem;
    font-size: 1rem;
    border-radius: 1rem;
    padding: 0.3rem;
}

.employee-selectors select {
    color: black;
}

// mobile
@media (max-width: 768px){
    .with-assists .employee-selectors select, .with-assists .employee-selectors span {
        margin: 0.3rem;
        font-size: 0.8rem;
        border-radius: 1rem;
        padding: 0.3rem;
    }

    .employee-selectors {
        margin: 0;
        max-width: 7rem;
    }
}