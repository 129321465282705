// theme

$background-primary: #282c34;
$background-alternate: #3c424f;
$background-nav: #3e4b6b;
$background-modal: rgba(40, 44, 52, 0.9);
$background-modal-compatible: rgb(40, 44, 52);

$text-primary: #fff;
$text-secondary: #d1c43c;

// transitions

$accordion-transition-speed: 150ms;
$accordion-transition-curve: ease-out;

$nav-transition-speed: 200ms;