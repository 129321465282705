@import '../../config';

.delete-icon {
    color: darkred;
    margin: 3%;
}

.edit-icon {
    margin: 3%;
}

// schedule accordion

div.schedule-accordion {
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 1rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    background-color: $background-alternate;
}

div.schedule-accordion dt {
    display: flex;
    justify-content: space-between;
}

div.schedule-accordion-desc {
    transition: max-height $accordion-transition-speed $accordion-transition-curve;
}

div.schedule-accordion-desc.hidden {
    max-height: 0;
}

div.schedule-accordion-desc.shown {
    max-height: 1000px;
}

div.schedule-accordion-desc dd {
    transition: font-size $accordion-transition-speed $accordion-transition-curve;
    margin-inline-start: 0;
}

div.schedule-accordion-desc.hidden dd {
    font-size: 0px;
}

.schedule-accordion-desc table {
    width: 90%;
    height: 90%;
    border-collapse: collapse;
    margin-left: 5%;
    margin-right: 5%;
}

.schedule-accordion-desc.shown tbody td {
    border-top: 1px solid gray;
}

div.schedule-accordion.with-assists .schedule-accordion-desc {
    font-size: 0.8rem;
}

.schedule-accordion button {
    width: 100%;
    background: transparent;
    border: none;
    color: $text-primary;
    font-weight: 100;
    font-size: 2rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.schedule-accordion button.current-month {
    color: $text-secondary;
}

// employee accordion

div.employee-accordion {
    margin-left: 20%;
    margin-right: 20%;
    margin-bottom: 1rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    background-color: $background-alternate;
}

div.employee-accordion dt {
    display: flex;
    justify-content: space-between;
}

div.employee-accordion-desc {
    transition: max-height $accordion-transition-speed $accordion-transition-curve;
}

div.employee-accordion-desc.hidden {
    max-height: 0;
}

div.employee-accordion-desc.shown {
    max-height: 10000px;
}

div.employee-accordion-desc dd {
    transition: font-size $accordion-transition-speed $accordion-transition-curve;
    margin-inline-start: 0;
}

div.employee-accordion-desc.hidden dd {
    font-size: 0px;
}

.employee-accordion-desc li {
    list-style-type: none;
}

.employee-accordion-desc table {
    width: 90%;
    height: 90%;
    border-collapse: collapse;
    margin-left: 5%;
    margin-right: 5%;
}

.employee-accordion-desc.shown tbody td {
    border-top: 1px solid gray;
}

.employee-accordion button {
    width: 100%;
    background: transparent;
    border: none;
    color: $text-primary;
    font-weight: 100;
    font-size: 1.4rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

p.highlight {
    color: $text-secondary;
    font-weight: 600;
}


// desktop
@media (min-width: 768px){
    // schedule accordion
    div.schedule-accordion {
        margin-left: 30%;
        margin-right: 30%;
    }

    // employee accordion
    div.employee-accordion {
        margin-left: 35%;
        margin-right: 35%;
    }
}