@import '../../config';

.back-btn {
    color: $text-secondary;
    background: transparent;
    border-style: none;
    font-size: 50px;
    display: flex;
}

h2 {
    padding-top: 1rem;
}

div.date-selectors {
    display: flex;
    justify-content: center;
}

.date-selectors select {
    margin: 1rem;
    width: 5rem;
    height: 2rem;
    font-size: 1rem;
    border-radius: 1rem;
}

div.employee-selector select {
    margin: 1rem;
    width: 10rem;
    text-align: center;
    border-radius: 1rem;
}

.new-schedule-item {
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 1rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
    border-radius: 1rem;
    background-color: $background-alternate;
}

.new-schedule-item h1 {
    padding: 1rem;
    margin: 0;
    color: $text-secondary;
}

.new-schedule-item-table {
    display: flex;
    justify-content: center;
    font-size: 1rem;
    padding-bottom: 1rem;
}

.new-schedule-item-table table {
    width: 90%;
}

.new-schedule-item-table thead {
    font-weight: bold;
}

.new-schedule-item-table tbody td {
    padding: 0.5rem;
}

#stats-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#stats-container h3 {
    color: $text-secondary
}

#stats-container p {
    height: 2rem;
}

.stats-col {
    padding: 0.5rem;
    max-width: 5rem;
}

.stats-page select {
    width: 6rem;
    height: 1.6rem;
    text-align: center;
    border-radius: 1rem;
}

div.stats-page {
    margin-left: 10%;
    margin-right: 10%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
    border-radius: 1rem;
    background-color: $background-alternate;
}

.assist-checkbox input {
    width: 1rem;
    margin: 0.5rem;
}

// desktop
@media (min-width: 768px){
    div.stats-page {
        margin-left: 20%;
        margin-right: 20%;
    }
}

// mobile
@media (max-width: 768px){
    .new-schedule-item-table.with-assists {
        font-size: 0.8rem;
    }

    #stats-container {
        font-size: 0.8rem;
    }
}