@import '../../config';

div.admin-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-menu button {
    font-size: 20px;
    font-weight: 500;
    width: 20rem;
    height: 3.5rem;
    margin: 1.5rem;
    background-color: $text-secondary;
    border-radius: 1rem;
    border-style: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
    color: black;
}

// desktop
@media (min-width: 768px){
    .admin-menu button {
        width: 30rem;
        height: 4rem;
    }
}