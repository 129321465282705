@import '../../config';

.error-alert {
    color: darkred;
}

form {
    margin-left: 10%;
    margin-right: 10%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    background-color: $background-alternate;
}

form input {
    background: transparent;
    margin: 5%;
    color: white;
}

form input {
    border-bottom: 1px solid white;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0px;
    padding: 1%;
    font-size: 18px;
    width: 70%;
}

form input[type=submit] {
    background-color: $text-secondary;
    color: black;
    border: none;
    font-size: 20px;
    border-radius: 10px;
    padding: 3%;
    width: 200px;
}


// desktop
@media (min-width: 768px){
    form {
        margin-left: 30%;
        margin-right: 30%;
    }
}