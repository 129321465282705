@import '../../config';

.nav-header {
    width: 100%;
    height: fit-content;
    background-color: $background-nav;
    position: fixed;
    top: 0px;
}

.nav-header a {
    padding: 0.5rem;
    color: $text-primary;
    text-decoration: none;
}

.nav-header ul {
    height: 100%;
    list-style: none;
    display: flex;
    padding: 1rem;
    margin: 0;
    align-items: center;
    justify-content: center;
}

.nav-header li {
    margin: 0 1rem;
    flex: 0.4;
}

.nav-header li span {
    padding: 0.2rem;
    border-bottom: 2px solid $background-nav;
    transition: border-bottom-color 0.3s;
}

.nav-header li span.active-tab {
    border-bottom-color: $text-secondary;
}

#title {
    margin: 0;
    font-size: 2rem;
    text-align: center;
    color: $text-secondary;
}

// desktop
@media (min-width: 768px){
    .nav-header li {
        margin: 0 3.5rem;
        flex: initial;
    }
}